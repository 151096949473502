import 'babel-polyfill'
import axios from 'axios';
import {
    MessageBox,
    Loading
} from 'element-ui';

var needLoadingRequestCount = 0 //当前正在请求的数量

// 创建axios实例
const service = axios.create({
    timeout: 20000 // 请求超时时间
})

// request请求拦截器
service.interceptors.request.use(config => {
    needLoadingRequestCount++ //每次发送一个请求就进行++
    Loading.service({
        lock: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    return config
}, err => {
    return Promise.reject(err)
})

// response响应拦截器
service.interceptors.response.use(res => {
    let loadingInstance = Loading.service();
    needLoadingRequestCount-- //调用完一个接口就进行-1
    if (needLoadingRequestCount == 0) { //当接口都调用完时
        setTimeout(function() {
            loadingInstance.close(); //关闭loading
        }, 100)
    }

    if (res.data.code == 200) {
        return res
    } else {
        MessageBox.alert(res.data.message, "提示", {
            dangerouslyUseHTMLString: true
        });
        return res
    }
}, err => {
    let loadingInstance = Loading.service();
    loadingInstance.close();
    MessageBox.alert("网络连接错误,请查询网络...", "提示", {
        dangerouslyUseHTMLString: true
    });
    return Promise.reject(err);
})

export default service