import request from './request.js'
//首页内容
export function apiHome(data) {
    return request({
        url: 'api/v1/index/getRequest',
        method: 'get',
        params: data
    })
}

//newlist
export function apiNewList(data) {
    return request({
        url: '/api/v1/mapleWorld/getArticleList',
        method: 'get',
        params: data
    })
}

// newListChildren
export function apiNewListChildren(data) {
    return request({
        url: '/api/v1/mapleWorld/getMoreList',
        method: 'get',
        params: data
    })
}

// newDetails
export function apiNewDetails(data) {
    return request({
        url: '/api/v1/mapleWorld/getArticleDetail',
        method: 'get',
        params: data
    })
}


//自驾租车页面数据
//获取车辆
export function apiSelfdriving(data) {
    return request({
        url: '/api/v1/carRental/getCarList',
        method: 'get',
        params: data
    })
}

//获取城市
export function apicitys(data) {
    return request({
        url: '/api/v1/public/getCitys',
        method: 'get',
        params: data
    })
}

//自驾租车筛选数据
export function apiscreen(data) {
    return request({
        url: '/api/v1/public/getModelsAndBrand',
        method: 'get',
        params: data
    })
}
//自驾租车页面数据

//获取长租页面的租车类型数据
export function apiCarModels(data) {
    return request({
        url: '/api/v1/getCarModels',
        method: 'get',
        params: data
    })
}

// 车辆详情  
export function apiGetCarDetail(data) {
    return request({
        url: '/api/v1/carRental/getCarDetail',
        method: 'get',
        params: data
    })
}
// 车辆详情

//表单提交
export function apiFormsumit(data) {
    return request({
        url: '/api/v1/DiscountsRent/long',
        method: 'post',
        params: data
    })
}
//获取长租页面的图片以及文字接口数据
export function apibannerLong(data) {
    return request({
        url: '/api/v1/public/getTopMedia',
        method: 'get',
        params: data
    })
}

//获取footer的数据
export function apiFooterMsg(data) {
    return request({
        url: '/api/v1/public/getBottomInfos',
        method: 'get',
        params: data
    })
}

// 获取套餐接口
export function apiGetBindRule(data) {
    return request({
        url: 'api/v1/carRental/getBindRule',
        method: 'get',
        params: data
    })
}
// 获取套餐接口

// 获取会员信息
export function apiGetMemberInfo(data) {
    return request({
        url: 'api/v1/carRental/getMemberInfo',
        method: 'get',
        params: data
    })
}
// 获取会员信息

// 走进枫叶中右侧菜单
export function apiGetFyMenu(data) {
    return request({
        url: '/api/v1/goFy/getFyMenu',
        method: 'get',
        params: data
    })
}
// 走进枫叶中右侧菜单

// 走进枫叶详情
export function apiGetArticleList(data) {
    return request({
        url: '/api/v1/goFy/getArticleList',
        method: 'get',
        params: data
    })
}
// 走进枫叶详情

//常见问题
export function apiGetproblemList(data) {
    return request({
        url: '/api/v1/Qa/getQaList',
        method: 'get',
        params: data
    })
}